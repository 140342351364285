// IMPORTS
@import (once) "/node_modules/nouislider/dist/nouislider.css";

@import (reference) "../settings.less";

// SIDEBAR
.sidebar {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

// SIDEBAR TREE
@media (min-width: 1280px) {
    .cms-rwd,
    .tree-header {
        display: none;
    }

    #tree {

        > nav {
            background-color: @sec-color;
            padding: 28px 0;
        }

        li {
            position: relative;

            &.downarrow {

                > button:after {
                    transform: scaleY(-1);
                }

                > ul {
                    display: block;
                }
            }

            ul {
                display: none;
            }
        }

            li.t0 {

                &:after {
                    position: absolute;
                    content: '';
                    height: 1px;
                    left: 32px;
                    right: 32px;
                    bottom: 0;
                    border-bottom: 1px dashed rgba(255,255,255, 0.15);
                }

                > a {
                    display: block;
                    color: @footer-gray;
                    font-weight: 300;
                    font-size: 14px;
                    letter-spacing: 0.1em;
                    padding: 12px 45px 12px 32px;
                }
            }

        a {
            position: relative;

            &:hover {
                text-decoration: underline;
            }
        }

        li:not(.t0) {

            a {
                color: @footer-gray;
                font-weight: 300;
                font-size: 14px;
                letter-spacing: 0.1em;
                padding: 6px 0;
                padding-right: 32px;
                display: block;
            }
        }

        li.t1 > a {
            padding-left: 40px;
        }

        li.t2 > a {
            padding-left: 48px;
        }

        li.t3 > a {
            padding-left: 56px;
        }

        li.selected {

            > a,
            button {
                color: @main-color;
            }
        }

        li.t0 > ul {
            padding-bottom: 6px;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 45px;
            height: 45px;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: @footer-gray;

            &:after {
                content: "\e91a";
                font-family: icomoon;
            }
        }

        small {
            /*            font-size: 12px;
            color: @font-color;
            position: absolute;
            top: 0;
            right: 0;
            font-weight: normal;
            height: 100%;
            width: 40px;
            display: flex;
            align-items: center;*/
            display: none;
        }
    }
}

// SIDEBAR BANNERS
@media (min-width: 1280px) {
    .sidebar-banners-wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .sidebar-banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        > figure {
            width: 100%;
            aspect-ratio: ~"280/160";

            img {
                .img-crop();
            }
        }

        > strong {
            font-size: 18px;
            font-family: @sec-font-family;
            line-height: 1.2;
            color: white;
            text-align: center;
            background: @sec-color;
            padding: 13px 32px;
            font-weight: normal;
            width: 100%;
        }

        &:hover > strong {
            color: @main-color;
        }
    }
}

// FILTER
.close-filter,
.close-filter-bottom,
.filter-trigger,
#filter,
#pills-container {
    display: none !important;
}

// STANDARD FILTER
body[data-canvas-filter] {

    &:has(#filter .gs-filtr-container) {

        .navigation-wrapper {
            display: flex;
        }
    }
}

.filter-sorter-wrapper {
    border: 4px solid @border-color;
    border-top: 0;
    border-bottom-width: 1px;
    padding: 24px 17px;
    
    .sorter {

        &:before {
            display: none;
        }
    }

    .sorter-button {
        min-width: 0;
        width: 100%;
    }
}

// LISTING WRAPPER
.listing-wrapper {
    .grid();
    grid-template-columns: minmax(0, 1fr);
}

// CATEGORY HEADLINE & DESCRIPTION
.category-headline {
    font-size: 40px;
    margin-bottom: 20px;
}

.category-description {
    margin-top: var(--rowGap);
    font-weight: 300;
    order: 2;
}

// NAVIGATION WRAPPER
.navigation-wrapper {
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
    gap: 12px 20px;

    &:has(#pills-container .single-pill),
    &:has(.dropdown-picker) {
        display: flex;
    }
}

.listing-wrapper:has(.products-list:empty) {

    .navigation-wrapper {
        display: none;
    }

    .dropdown-picker {
        display: none;
    }
}

// DROPDOWN PICKER
.dropdown-picker {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10;

    &.show-dropdown {

        .dropdown-button:after {
            transform: scaleY(-1);
        }

        .dropdown-listing {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }

    &:before {
        background-color: white;
        font-size: 14px;
        font-weight: 700;
        color: @header-color;
        margin-bottom: 2px;
    }

    &[data-dropdown-type="sort"]:before {
        content: 'Sortowanie';
    }

    &[data-dropdown-type="pageSize"] {

        &:before {
            content: 'Ilość';
        }

        .dropdown-button {
            min-width: 173px;
        }
    }
}

.dropdown-button {
    border: 1px solid @header-color;
    color: @font-color;
    min-width: 256px;
    height: 40px;
    text-align: left;
    position: relative;
    padding: 0 40px 0 20px;
    z-index: 1;

    &:after {
        content: "\e91a";
        font-family: icomoon;
        position: absolute;
        top: -1px;
        right: -1px;
        width: 40px;
        height: 40px;
        color: @header-color;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        border-color: @main-color;
    }
}

.dropdown-listing {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid @border-color;
    border-top: 0;
    .shadow();
    min-width: 100%;
    padding: 5px 0;
    transform: translateY(-5px);
    transition: all .2s ease-in-out;

    span {
        display: block;
    }

    input {
        display: none;

        &:checked + label {
            font-weight: 700;
            color: @header-color;
            text-decoration: none !important
        }
    }

    label {
        display: block;
        padding: 5px 20px;
        user-select: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

// IE FALLBACK
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .sorter-button:before {
        display: none;
    }
}